import React, {MutableRefObject} from 'react';
import {Col, Row} from "react-bootstrap";
import a4pLogo from "../resources/images/a4p_logo.svg";
import {Link} from "react-router-dom";

export interface KontaktProps {
  scrollRef: MutableRefObject<HTMLDivElement | null>;
}


const Kontakt = (props: KontaktProps) => {

  return (
      <Row ref={props.scrollRef} className="kontakt-background  pt-10  pb-5">
        <Col xs={{span: 10, offset: 2}} lg={{span: 10, offset: 2}}>
          <Row className="">
            <Col xs={12} md={4}>
              <ul className="bottom-list">
                <li className="pt-3 bottom-list-item font-weight-bold">apps4people</li>
                <li className="bottom-list-item">Am Bach 8</li>
                <li className="bottom-list-item">CH-5502 Hunzenschwil</li>
                <li className="bottom-list-item">www.apps4people.ch</li>
                <li className="bottom-list-item">info@apps4people.ch</li>
              </ul>
            </Col>
            <Col xs={12} md={4} className="pt-4 pt-md-0">
              <ul className="bottom-list">
                <li className="pt-3 bottom-list-item font-weight-bold">Kompetenzen</li>
                <li className="bottom-list-item">Design-Thinking</li>
                <li className="bottom-list-item">Agile Methodologies</li>
                <li className="bottom-list-item">Software-Engineering</li>
                <li className="bottom-list-item">Web-Technologies</li>
                <li className="bottom-list-item">Databases</li>
                <li className="bottom-list-item">Schnittstellen</li>
              </ul>
            </Col>
          </Row>
          <Row className="pt-5">
            <Col xs={12}>
              <img width="80px" alt="apps4people" src={a4pLogo} className="pb-2"/>
              <ul className="bottom-list">
                <li className="bottom-list-item float-left pr-3"><small>
                  <Link
                    to="/impressum">Impressum</Link></small></li>
                <li className="bottom-list-item float-left pr-3"><small><Link to="datenschutz">Datenschutzerklärung</Link></small>
                </li>
              </ul>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col xs={12}>
              <ul className="bottom-list">
                <li className="bottom-list-item font-weight-bold float-left pr-3">
                  <small>&copy;2021&nbsp;apps4people&nbsp;-&nbsp;All&nbsp;rights&nbsp;reserved</small>
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
  );
};

Kontakt.propTypes = {};

export default Kontakt;
