import React, {MutableRefObject, useRef} from 'react';
import './App.css';
import './styles/main.css';
import {Col, Nav, Navbar, Row} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'holderjs';
import a4pLogo from './resources/images/a4p_logo.svg';
import Hero from "./components/Hero";
import Apps4People from "./components/Apps4people";
import CookieConsent from "react-cookie-consent";
import Work from "./components/Work";
import Kontakt from "./components/Kontakt";
import Customers from "./components/Customers";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Impressum from "./components/Impressum";
import {LinkContainer} from "react-router-bootstrap";
import NavbarBack from "./components/NavbarBack";
import Datenschutz from "./components/Datenschutz";

const App = () => {

  const heroRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const workRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const companyRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const kontaktRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const kundenRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  const executeScroll = (param: MutableRefObject<HTMLDivElement | null>) => {
    param?.current?.scrollIntoView({behavior: 'smooth'});
  }

  return (
      <Router>

        <div className="App">


          <Switch>

            <Route className="p-0 m-0" path="/impressum">
              <NavbarBack/>

              <Impressum/>
            </Route>

            <Route className="p-0 m-0" path="/datenschutz">
              <NavbarBack/>

              <Datenschutz/>
            </Route>

            <Route className="p-0 m-0" path="/">
              <Navbar variant="dark" className="navbar-green fixed-top shadow" expand="md">
                <Navbar.Brand href="#home"><img width="120px" alt="apps4people" src={a4pLogo}
                                                className="m-3"/></Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="mr-auto">
                    <LinkContainer to="/">
                      <Nav.Link href="#home" onClick={() => executeScroll(heroRef)}
                                className="text-uppercase">Home</Nav.Link>
                    </LinkContainer>
                    <Nav.Link href="#work" onClick={() => executeScroll(workRef)}
                              className="text-uppercase">Work</Nav.Link>
                    <Nav.Link href="#unternehmen" onClick={() => executeScroll(companyRef)}
                              className="text-uppercase">Unternehmen</Nav.Link>
                    <Nav.Link href="#kunden" onClick={() => executeScroll(kundenRef)}
                              className="text-uppercase">Kunden</Nav.Link>
                    <Nav.Link href="#kontakt" onClick={() => executeScroll(kontaktRef)}
                              className="text-uppercase">Kontakt</Nav.Link>
                  </Nav>
                  <Nav className="pt-4 pt-sm-0">
                    <Row>
                      <Col>
                        <ul className="bottom-list">
                          <li className="bottom-list-item"><strong>+41 62 877 15 15</strong></li>
                          <li className="bottom-list-item"><a
                              href="mailto: hallo@apps4people.ch"><strong>hallo@apps4people.ch</strong></a>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>


              <Hero scrollRef={heroRef}/>

              <Work scrollRef={workRef}/>

              <Apps4People scrollRef={companyRef}/>

              {/*<Teaser scrollRef={teaserRef}/>*/}

              <Customers scrollRef={kundenRef}/>

              <Kontakt scrollRef={kontaktRef}/>
            </Route>

          </Switch>


          <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>

        </div>
      </Router>


  )
}

export default App;
