import React, {MutableRefObject} from 'react';
import {Col, Row} from "react-bootstrap";

export interface Apps4peopleProps {
  scrollRef: MutableRefObject<HTMLDivElement | null>
}

const Apps4People = (props: Apps4peopleProps) => {
  return (
      <React.Fragment>
        <Row ref={props.scrollRef} className="a4p-background pt-10  pb-5">
          <Col>
            <div className="card-body">
              <Row>
                <Col xs={8} md={{span: 8, offset: 1}} lg={{span: 5, offset: 1}}>
                  <div className="pb-3 display-2">Ihre digital Agentur</div>
                  <p><strong className="a4p-green">apps4people</strong> ist ihre unabhängige
                    Digital-Agentur mit einem besonderen Fokus auf E-Commerce. Wir <strong className="a4p-green">entwickeln Applikationen</strong>, die einfach zu benutzen sind und einen hohen ROI bieten..</p>

                  <p>Dank <strong className="a4p-green">langjähriger Erfahrung</strong> bieten wir Ihnen mit unserem Team innovative Softwareentwicklung, mit einem modernen Software-Stack und hoher Methodenkompetenz im Management des gesamten Projektes.</p>
                  <p>Die <strong className="a4p-green">partnerschaftliche und transparente Zusammenarbeit</strong> ist dabei unsere Grundlage, um Ihr Vorhaben erfolgreich umzusetzen und gemeinsam zu wachsen.</p>
                  <a className="a4p-green" href="mailto: hallo@apps4people.ch">Wollen Sie mehr wissen?</a>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>


      </React.Fragment>
  );
};

export default Apps4People;
