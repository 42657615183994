import React from 'react';
import {Col, Nav, Navbar, Row} from "react-bootstrap";
import a4pLogo from "../resources/images/a4p_logo.svg";
import {LinkContainer} from "react-router-bootstrap";

const NavbarBack = () => {


  return (
      <Navbar variant="dark" className="navbar-green fixed-top shadow" expand="md">
        <LinkContainer to="/">
        <Navbar.Brand href="#home"><img width="120px" alt="apps4people" src={a4pLogo}
                                        className="m-3"/></Navbar.Brand>
        </LinkContainer>

        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <LinkContainer to="/">
              <Nav.Link className="text-uppercase">Zurück</Nav.Link>
            </LinkContainer>
          </Nav>
          <Nav className="pt-4 pt-sm-0">
            <Row>
              <Col>
                <ul className="bottom-list">
                  <li className="bottom-list-item"><strong>+41 62 877 15 15</strong></li>
                  <li className="bottom-list-item"><a
                      href="mailto: info@apps4people.ch"><strong>info@apps4people.ch</strong></a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Nav>
        </Navbar.Collapse>
      </Navbar>);
};


export default NavbarBack;
