import React, {MutableRefObject} from 'react';
import {Col, Row} from "react-bootstrap";
import {
  FaAppStoreIos,
  FaAtom,
  FaChalkboard,
  FaGlobe,
  FaHandshake,
  FaShoppingCart
} from "react-icons/all";
import {IconContext} from 'react-icons';

export interface WorkProps {
  scrollRef: MutableRefObject<HTMLDivElement | null>
}

const Work = (props: WorkProps) => {
  return (
      <React.Fragment>
        <IconContext.Provider value={{className: "a4picons"}}>
          <Row ref={props.scrollRef} className="pt-10 pb-5">
            <Col>
              <div className="pl-5 pr-5 text-center display-3">
                Wir machen Software für Menschen
              </div>
              <Row className="pt-3 font-weight-bold">
                <Col xs={{span: 8, offset: 2}} className="text-center">
                  Applikationen sollen sich in Design und Entwicklung konsequent an ihren Anwendern orientieren. Unsere Herangehensweise basiert auf Human Centered Design und wir entwickeln mit Ihnen für Sie und Ihre Kunden die passenden Lösungen.
                </Col>
              </Row>
            </Col>
          </Row>


          <Row className="pt-1 pl-5 pr-5">
            <Col xs={12} md={4} className="pt-2">
              <div className="card h-100">
                <div className="card-body">
                  <FaShoppingCart/>
                  <h2 className="a4p card-title">Wir sind ihr<br/><span
                      className="a4p-green">Onlineshop</span></h2>
                  <p className="card-text">
                    Wir sind Onlineshop. Mit unserem holistischen Ansatz und unserer Erfahrung, setzen wir auch Ihren Shop erfolgreich um.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4} className="pt-2">
              <div className="card h-100">
                <div className="card-body">
                  <FaGlobe/>
                  <h2 className="card-title">Wir sind ihre<br/><span
                      className="a4p-green">Webapp</span></h2>
                  <p className="card-text">
                    Wir entwickeln moderne Webanwendungen. Von einfachen Websites bis hin zu
                    komplexen
                    Webapps.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4} className="pt-2">
              <div className="card h-100">
                <div className="card-body">
                  <FaAppStoreIos/>
                  <h2 className="card-title">Wir sind ihre<br/><span
                      className="a4p-green">Mobile-App</span></h2>
                  <p className="card-text">
                    Von native bis hybrid, wir kennen die Technologien, beraten Sie dazu und bringen
                    Ihre
                    Applikation in die App-Stores.
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="pl-5 pr-5 pb-5">
            <Col xs={12} md={4} className="pt-2">
              <div className="card h-100">
                <div className="card-body">
                  <FaAtom/>
                  <h2 className="a4p card-title">Wir sind<br/><span
                      className="a4p-green">Software Engineering</span></h2>
                  <p className="card-text">
                    Wir decken den kompletten Software Development Lifecycle ab und führen Projekte
                    nach agiler Methodik durch.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4} className="pt-2">
              <div className="card h-100">
                <div className="card-body">
                  <FaHandshake/>
                  <h2 className="card-title">Wir sind<br/><span
                      className="a4p-green">Consulting</span></h2>
                  <p className="card-text">
                    Wir unterstützen Sie bei der digitalen Transformation, von der Strategie, bis hin zur Implementierung.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4} className="pt-2">
              <div className="card h-100">
                <div className="card-body">
                  <FaChalkboard/>
                  <h2 className="card-title">Wir sind<br/><span
                      className="a4p-green">Schulung</span></h2>
                  <p className="card-text">
                    Wir vermitteln das Wissen und die Fähigkeiten, implementierte Lösungen sicher und kompetent anzuwenden.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </IconContext.Provider>

      </React.Fragment>
  );
};

export default Work;
