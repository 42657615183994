import React, {MutableRefObject} from 'react';
import {Col, Row} from "react-bootstrap";

export interface HeroProps {
  scrollRef: MutableRefObject<HTMLDivElement | null>
}

const Hero = (props: HeroProps) => {
  return (
      <Row ref={props.scrollRef} className="hero-background">
        <Col xs={12} className="d-flex justify-content-center align-self-center">
          <div className="display-1 text-center justify-content-center align-self-center">Wir sind<br/><span className="a4p-green font-weight-bold">apps4people</span></div>
        </Col>
      </Row>
  );
};


export default Hero;
