import React, {useEffect} from 'react';
import {Col, Row} from "react-bootstrap";

const Datenschutz = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (


      <Row className="a4p-green-background  pt-8  pb-5">
        <Col xs={{span: 10, offset: 1}} className="d-flex justify-content-center align-self-center">
          <div className="text-white">


            <h2>Datenschutzerkl&auml;rung</h2>
            <p>Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</p>

            <br/>

            <p>apps4people, Christian Spiewok
              <br/>Christian Spiewok
              <br/>Am Bach 8
              <br/>5502 Hunzenschwil</p>
            <p>Telefon: 0628771515
              <br/>E-Mail: christian@apps4people.ch
              <br/>WebSite: https://www.apps4people.ch/datenschutz</p>
            <p>&nbsp;</p>

            <h2>Allgemeiner Hinweis</h2>
            <p>Gest&uuml;tzt auf Artikel 13 der schweizerischen Bundesverfassung und den
              datenschutzrechtlichen Bestimmungen des Bundes (Datenschutzgesetz, DSG
              hat jede Person Anspruch auf Schutz ihrer Privatsph&auml;re sowie auf Schutz vor
              Missbrauch ihrer pers&ouml;nlichen Daten. Die Betreiber dieser Seiten nehmen den
              Schutz Ihrer pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen
              Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie
              dieser Datenschutzerkl&auml;rung.</p>


            <p>In Zusammenarbeit mit unseren Hosting-Providern bem&uuml;hen wir uns, die Datenbanken so gut wie m&ouml;glich vor
            fremden Zugriffen, Verlusten, Missbrauch oder vor F&auml;lschung zu sch&uuml;tzen.</p>

            <p>Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.B. bei der
              Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser
              Schutz der Daten vor dem Zugriff durch Dritte ist nicht m&ouml;glich.</p>

            <p>Durch die
            Nutzung dieser Website erkl&auml;ren Sie sich mit der Erhebung, Verarbeitung und Nutzung
            von Daten gem&auml;ss der nachfolgenden Beschreibung einverstanden. Diese Website kann
            grunds&auml;tzlich ohne Registrierung besucht werden. Dabei werden Daten wie
            beispielsweise aufgerufene Seiten bzw. Namen der abgerufenen Datei, Datum und Uhrzeit zu
            statistischen Zwecken auf dem Server gespeichert, ohne dass diese Daten unmittelbar auf
            Ihre Person bezogen werden. Personenbezogene Daten, insbesondere Name, Adresse oder
            E-Mail-Adresse werden soweit m&ouml;glich auf freiwilliger Basis erhoben. Ohne Ihre
            Einwilligung erfolgt keine Weitergabe der Daten an Dritte.</p>

            <p>&nbsp;</p>

            <h2>Datenschutzerkl&auml;rung f&uuml;r Cookies</h2><p>Diese Website verwendet Cookies.
            Das sind kleine Textdateien, die es m&ouml;glich machen, auf dem Endger&auml;t des
            Nutzers spezifische, auf den Nutzer bezogene Informationen zu speichern, w&auml;hrend er
            die Website nutzt. Cookies erm&ouml;glichen es, insbesondere Nutzungsh&auml;ufigkeit und
            Nutzeranzahl der Seiten zu ermitteln, Verhaltensweisen der Seitennutzung zu analysieren,
            aber auch unser Angebot kundenfreundlicher zu gestalten. Cookies
            bleiben &uuml;ber das Ende einer Browser-Sitzung gespeichert und k&ouml;nnen bei einem
            erneuten Seitenbesuch wieder aufgerufen werden. Wenn Sie das nicht w&uuml;nschen,
            sollten Sie Ihren Internetbrowser so einstellen, dass er die Annahme von Cookies
            verweigert.</p> <p>Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des
            Onlinemarketing eingesetzten Cookies kann bei einer Vielzahl der Dienste, vor allem im
            Fall des Trackings, über die US-amerikanische Seite <a
                href="http://www.aboutads.info/choices/" target="_blank"
                rel="noopener">http://www.aboutads.info/choices/</a> oder die EU-Seite <a
                href="http://www.youronlinechoices.com/" target="_blank"
                rel="noopener">http://www.youronlinechoices.com/</a> erklärt werden. Des Weiteren
            kann die Speicherung von Cookies mittels deren Abschaltung in den Einstellungen des
            Browsers erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls nicht alle
            Funktionen dieses Onlineangebotes genutzt werden können.</p> <p>&nbsp;</p>

            <h2>Datenschutzerkl&auml;rung f&uuml;r SSL-/TLS-Verschl&uuml;sselung</h2><p>Diese
            Website nutzt aus Gr&uuml;nden der Sicherheit und zum Schutz der &Uuml;bertragung
            vertraulicher Inhalte, wie zum Beispiel der Anfragen, die Sie an uns als Seitenbetreiber
            senden, eine SSL-/TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung erkennen
            Sie daran, dass die Adresszeile des Browsers
            von &quot;http://&quot; auf &quot;https://&quot; wechselt und an dem Schloss-Symbol in
            Ihrer Browserzeile.</p> <p>Wenn die SSL bzw. TLS Verschl&uuml;sselung aktiviert ist,
            k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten mitgelesen
            werden.</p><p>&nbsp;</p><h2>Urheberrechte</h2> <p>Die Urheber- und alle anderen Rechte
            an Inhalten, Bildern, Fotos oder anderen Dateien auf der Website, geh&ouml;ren
            ausschliesslich dem Betreiber dieser Website oder den speziell genannten Rechteinhabern.
            Für die Reproduktion von sämtlichen Dateien, ist die schriftliche Zustimmung des
            Urheberrechtstr&auml;gers im Voraus einzuholen.</p><p>Wer ohne Einwilligung des
            jeweiligen Rechteinhabers eine Urheberrechtsverletzung begeht, kann sich strafbar und
            allenfalls schadenersatzpflichtig machen.</p><p>&nbsp;</p><h2>&Auml;nderungen</h2><p>Wir
            k&ouml;nnen diese Datenschutzerkl&auml;rung jederzeit ohne Vorank&uuml;ndigung anpassen.
            Es gilt die jeweils aktuelle, auf unserer Website publizierte Fassung. Soweit die
            Datenschutzerkl&auml;rung Teil einer Vereinbarung mit Ihnen ist, werden wir Sie im Falle
            einer Aktualisierung über die &Auml;nderung per E-Mail oder auf andere geeignete Weise
            informieren.</p><p>&nbsp;</p><h2>Fragen an den Datenschutzbeauftragten</h2> <p>Wenn Sie
            Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich
            direkt an die f&uuml;r den Datenschutz zu Beginn der Datenschutzerkl&auml;rung
            aufgef&uuml;hrten, verantwortlichen Person in unserer Organisation.</p><p>&nbsp;</p>
            <p>Aarau, 22.02.2021
            {/*  {{ /* <!--ACHTUNG: Wenn Sie die Quelle ohne Erlaubnis von SwissAnwalt entfernen, dann begehen Sie eine Urheberrechtsverletzung welche in jedem Fall unter Kostenfolge geahndet wird.--> *!/}*/}
              <br/>
              Quelle: <a href="https://www.swissanwalt.ch" target="_blank"
                         rel="noopener">SwissAnwalt</a></p>
            {/*{/!* <!--Bitte beachten Sie die AGB von SwissAnwalt betreffend allfällig anfallenden Kosten bei Weglassen der Quelle!-->s *!/}*/}


          </div>
        </Col>
      </Row>

  );
};


export default Datenschutz;
