import React, {MutableRefObject} from 'react';
import {Carousel, Col, Row} from "react-bootstrap";
import noshpots_logo from "../resources/images/nosh_pots_logo.png";
import vfm_logo from "../resources/images/vfm_logo.jpg";
import mary_and_plants_logo from "../resources/images/mary_and_plants_logo.png";

export interface KundenProps {
  scrollRef: MutableRefObject<HTMLDivElement | null>;
}

const Customers = (props: KundenProps) => {
  return (
      <Row ref={props.scrollRef} className="p-5 a4p-green-light-background">
        <Col className="">
          <Carousel controls={false}>
            <Carousel.Item>
              <div
                  className="h-100 text-center d-flex flex-column justify-content-center align-items-center p-3"
                  style={{height: "380px"}}>
                <img style={{width: "160px"}} src={mary_and_plants_logo} alt="Mary and Plants"/>
                <h3 className="mt-4 a4p-green"><a className="a4p-green" href={"http://www.maryandplants.ch"}>Mary and Plants</a>, Online-Shop, Schnittstellen,
                  iOS-Applikation</h3>
                <Row>
                  <Col md={{span: 4, offset: 4}}>
                    <p className="small a4p-green">Die Zusammenarbeit mit apps4people war äusserst
                      erfolgreich und angenehm. Christian ist sehr professionell und bringt eine
                      Menge
                      Erfahrung mit, die er in Konzeption und Entwicklung einfliessen lässt. Er geht
                      die
                      extra Meile und liefert sogar mehr als versprochen wurde. Und das zu absolut
                      fairen Preisen! Kommunikation und Kooperation waren stets sehr angenehm und
                      auf
                      einer vertrauensvollen Basis. Wir freuen uns schon auf die nächsten Projekte
                      mit
                      apps4people!</p>
                  </Col>
                </Row>
                <p className="small font-weight-bold a4p-green">Ivan Putincanin, Mary And Plants AG, Zürich</p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div
                  className="h-100 text-center d-flex flex-column justify-content-center align-items-center p-3"
                  style={{height: "380px"}}>
                <img style={{width: "160px"}} src={noshpots_logo} alt="Noshpots"/>
                <h3 className="mt-4 a4p-green"><a className="a4p-green" href={"http://www.noshpots.ch"}>NoshPots Onlineshop</a></h3>
                <Row>
                  <Col md={{span: 4, offset: 4}}>
                    <p className="small a4p-green">Die Zusammenarbeit mit apps4people war für uns äusserst
                      angenehm und produktiv. Sie
                      verstehen es auf spezifische Kundendedürfnisse einzugehen und
                      massgeschneiderte
                      Lösungen
                      zu erarbeiten.</p>
                  </Col>
                </Row>
                <p className="small font-weight-bold a4p-green">Ralf Schmitz, NoshPots GmbH, Zürich</p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div
                  className="h-100 text-center d-flex flex-column justify-content-center align-items-center p-3"
                  style={{height: "380px"}}>
                <img style={{width: "160px"}} src={vfm_logo} alt="Noshpots"/>
                <h3 className="mt-4 a4p-green">VFM Versicherungsmanagement, A-Sulz</h3>
                <Row>
                  <Col md={{span: 4, offset: 4}}>
                    <p className="small a4p-green">Ich bin sehr froh das ich apps4people kennen
                      lernen durfte.
                      Sie waren phänomenal in der
                      Umsetzung meiner Wünsche für die neue Software. Sie hatten sehr gute Ideen und
                      es
                      wurde
                      alles prompt und professionell durchgeführt.
                      Auch bei den Kosten waren sie mehr als nur fair. Ich kann apps4people nur
                      bestens
                      weiterempfehlen.</p>
                  </Col>
                </Row>
                <p className="small font-weight-bold a4p-green">Peter Kathan,<br/>VFM Versicherungsmanagement GmbH, A-Sulz</p>
              </div>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
  );
};

Customers.propTypes = {};

export default Customers;
